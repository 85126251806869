<template>
    <div class="portfolio">
        <canvas></canvas>
        <div class="container">
            <div class="row">
                <h1>{{ title }}</h1>
            </div>
            <div class="items">
                <PortfolioItem v-for="(item, i) in portfolio" v-if="item.published" :key="i" :item="item" />
            </div>
            <div class="row" v-if="is_home && length > 7">
                <router-link to="/portfolio" class="button" v-if="is_home">
                    Alles bekijken
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import PortfolioItem from "@/components/portfolio/PortfolioItem";

export default {
    name: "PortfolioSection",
    components: {PortfolioItem},
    props: ['section'],
    created() {
        this.$store.dispatch('loadPortfolio')
    },
    computed: {
        title() {
            return this.section?.title ?? "Portfolio"
        },
        portfolio() {
            return this.$store.getters.portfolio ?? [];
        },
        is_home() {
            return this.$route.path === "/"
        },
        length() {
            return this.portfolio.length ?? 0
        }
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/sections/Portfolio";
</style>
