<template>
    <main id="page" v-if="page != null">
        <section v-for="(module, m) in modules" :key="m">
            <banner-section v-if="module.type.toLowerCase() === 'banner'" :section="module" />
            <portfolio-section v-else-if="module.type.toLowerCase() === 'portfolio'" :section="module" />
            <content-section v-else-if="module.type.toLowerCase() === 'content'" :section="module" />
            <socials-banner-section v-else-if="module.type.toLowerCase() === 'socials'" :section="module" />
        </section>
    </main>
</template>
<script>
import {absolute} from "@/App";
import Banner from "@/components/Banner";
import BannerSection from "@/components/sections/BannerSection";
import PortfolioSection from "@/components/sections/PortfolioSection";
import ContentSection from "@/components/sections/ContentSection";
import SocialsBannerSection from "@/components/sections/SocialsBannerSection";
import UnknownView from "@/views/UnknownView";

export default {
    name: "PageView",
    components: {
        UnknownView,
        SocialsBannerSection,
        ContentSection,
        PortfolioSection,
        BannerSection,
        Banner
    },
    metaInfo() {
        return {
            title: this.title ?? "Home",
            meta: [
                {name: 'title', content: this.title ?? "Home"},
                {name: 'og:title', content: this.title ?? "Home"},
                {name: 'description', content: this.description ?? this.resources?.description ?? "Kristel Jansen"},
                {name: 'og:description', content: this.description ?? this.resources?.description ?? "Kristel Jansen"},
                {name: 'keywords', content: this.resources?.tags ?? ""},
                {name: 'og:keywords', content: this.resources?.tags ?? ""},
            ]
        }
    },
    props: {
        resources: {
            type: Object
        }
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler() {
                this.$emit('loaded', false)
                this.$store.dispatch('loadPage', this.path)
            }
        },
        page: {
            deep: true,
            handler() {
                this.$emit('title', this.title)
                this.$emit('loaded', this.page != null)
            }
        },
    },
    computed: {
        path() {
            if (this.$route.path === '/'){
                return 'root'
            }
            return this.$route.path.replace('/', '')
        },
        page() {
            return this.$store.getters.page ?? null
        },
        title() {
            return this.page?.title ?? "Home"
        },
        description() {
            if (`${this.page?.description ?? ''}`.length > 0) {
                return this.page?.description ?? null
            }
            return null
        },
        img() {
            return this.page?.image ?? null
        },
        modules() {
            return this.page?.modules ?? []
        },
        image() {
            let image = this.page?.image?.path ?? null;
            if (image == null) {
                return null
            }
            return absolute(image)
        },
        logo_image() {
            let image = this.resources?.logo ?? null
            if (image == null) {
                return null
            }
            return absolute(image.path)
        },
        root() {
            return this.page?.root ?? this.$route.path === '/' ?? false
        }
    },
    methods: {
        onLoaded() {
            this.$emit('loaded', true)
        }
    }
}
</script>
<style scoped>
</style>
