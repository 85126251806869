<template>
    <div class="content-section">
        <div class="wrapper">
            <div class="container">
                <div class="row">
                    <h2>{{ title }}</h2>
                </div>
                <div class="row">
                    <div class="content" v-for="(content, c) in contents" :key="c" v-html="content"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {absolute} from "@/App";

export default {
    name: "ContentSection",
    props: ['section'],
    computed: {
        title() {
            return this.section?.title ?? null
        },
        content() {
            return this.section?.content ?? null
        },
        contents() {
            let content = `${this.content ?? "<p></p>"}`.replaceAll('/cockpit', absolute("cockpit"));
            if (content.indexOf('[button]') !== -1) {
                this.button = true;
                content = content.replaceAll('[button]', '')
            }
            if (content.indexOf('[split]') !== -1) {
                content = content.replaceAll("[split]", "</p>[split]<p>")
                return content.replaceAll("<p></p>", "").split('[split]')
            }
            return [content];
        }
    }
}
</script>
<style scoped>
</style>
