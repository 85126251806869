<template>
    <div class="banner" :class="contents.length > 1?'text': ''">
        <img v-if="image != null" :src="image" alt="Image banner" />
        <div class="overlay">
            <div class="center" :class="button?'show-button': ''">
                <div class="content row" v-for="(content, c) in contents" :key="c" v-html="content">
                </div>
                <div class="button-row row" v-if="button">
                    <router-link to="/contact" class="button big">
                        Let's get started!
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {absolute} from "@/App";

export default {
    name: "BannerSection",
    props: ['section'],
    metaInfo() {
        return {
            meta: [
                {name: 'image', content: this.image},
                {name: 'og:image', content: this.image}
            ]
        }
    },
    watch: {
        $route() {
            this.response = this.section;
        },
        section() {
            this.response = this.section;
        }
    },
    computed: {
        image() {
            let image = this.section.image ?? null;
            if (image == null) {
                return null;
            }
            return absolute(image.path);
        },
        content() {
            return this.section?.content ?? null
        },
        contents() {
            let content = `${this.content ?? "<p></p>"}`.replaceAll('/cockpit', absolute("cockpit"));
            if (this.button) {
                content = content.replaceAll('[button]', '')
            }
            if (content.indexOf('[split]') !== -1) {
                content = content.replaceAll("[split]", "</p>[split]<p>")
                return content.replaceAll("<p></p>", "").split('[split]')
            }
            return [content];
        },
        button() {
            return `${this.content ?? ''}`.includes('[button]');
        }
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/sections/Banner";
</style>
