<template>
</template>
<script>
    export default {
        name: "Banner",
        props: ['image']
    }
</script>
<style scoped>
</style>
