<template>
    <div class="item" @mouseover="play" @mouseleave="pause">
        <router-link class="box" :to="link">
            <div class="content">
                <div class="title">
                    <h3>{{ title }}</h3>
                    <h5>{{ date }} </h5>
                    <span class="button small">Bekijken</span>
                </div>
            </div>
        </router-link>
        <!--        <canvas :id="`this-canvas-${id}`" class="image" ></canvas>-->
        <video v-if="is_video" :id="`this-video-${id}`" class="image" :src="path" loop @loadeddata="reloadRandomFrame"></video>
        <img v-else :src="path" :alt="title" class="image" :style="{backgroundColor: color, transform: `zoom(1)`}">
    </div>
</template>
<script>
import {absolute} from "@/App";

export default {
    name: "PortfolioItem",
    props: ['item'],
    data() {
        return {
            video: HTMLVideoElement,
            canvas: null,
            context: null
        }
    },
    created() {
        this.video = document.getElementById("this-video-" + this.id);
    },
    computed: {
        id() {
            return this.item?._id ?? null
        },
        title() {
            return this.item?.title ?? null
        },
        title_slug() {
            return this.item?.title_slug ?? null
        },
        link() {
            return `/portfolio/${this.title_slug}`
        },
        image() {
            return this.item?.image ?? null
        },
        mime() {
            return this.image?.mime ?? null
        },
        is_video() {
            return this.image?.video ?? null
        },
        youtube_link() {
            return this.item?.youtube_link ?? null
        },
        is_youtube() {
            return (this.youtube_link != null)
        },
        youtube_id() {
            if (this.is_youtube) {
                if (this.youtube_link.includes(',')) {
                    let links = this.youtube_link?.split(',') ?? [];
                    for (let y in links) {
                        let url = new URL(links[y])
                        return url.searchParams.get("v") || null;
                    }
                }
                let url = new URL(this.youtube_link)
                return url.searchParams.get("v") || null;
            }
            return null
        },
        created() {
            return this.item?.date ?? null
        },
        date() {
            let months = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"]
            let date = new Date(this.created);
            let m = months[date.getMonth()];
            let d = date.getDate();
            return `${d < 10? `0${d}` : d} ${m < 10? `0${m}` : m} ${date.getFullYear()}`
        },
        path() {
            let image = this.image?.path ?? null
            if (image != null) {
                return absolute(`cockpit/storage/uploads${image}`);
            }
            if (this.is_youtube) {
                if (this.youtube_id != null) {
                    return `https://img.youtube.com/vi/${this.youtube_id}/mqdefault.jpg`
                }
                return "";
            }
            return null;
        },
        color() {
            return (this.image?.colors ?? ['#000'])[0]
        }
    },
    methods: {
        reloadRandomFrame() {
            if (this.video instanceof HTMLVideoElement) {
                this.video.volume = 0
                this.video.defaultPlaybackRate = 2.0;
                this.video.repeat = true
                this.video.play();
                this.video.pause();
                console.log(this.video.duration)
                // if (this.video.duration > 5) {
                //     setInterval(this.reloadVideo, 5000)
                // }
            }
        },
        reloadVideo() {
            this.video.currentTime = 0;
        },
        play() {
            if (this.is_video) {
                this.video.play();
                if (this.video.duration > 5) {
                    this.video.currentTime = 0;
                }
            }
        },
        pause() {
            if (this.is_video) {
                this.video.pause();
            }
        }
    }
}
</script>
<style scoped>
canvas {
    background-color: green;
}
</style>
