<template>
    <banner-section :section="section" />
</template>
<script>
import Banner from "@/components/Banner";
import BannerSection from "@/components/sections/BannerSection";

export default {
    name: "UnknownView",
    components: {BannerSection, Banner},
    data: () => ({
        section: {
            title: "Hi",
            content: "<h1>Pagina niet gevonden...</h1><br><span><a href='/'>Naar de homepagina</a></span>"
        }
    }),
    metaInfo() {
        console.log("404 Niet gevonden")
        this.$emit('loaded', true)
        return {
            title: "Niet gevonden",
            titleTemplate: "%s | Kristal Design"
        }
    },
    beforeCreate() {
        console.log("404 Niet gevonden")
        this.$emit('loaded', true)
    },
    created() {
        console.log("404 Niet gevonden")
        this.$emit('loaded', true)
    }
}
</script>
<style scoped>
</style>
