<template>
    <div class="banner contact">
        <img :src="image" alt="Image banner" />
        <div class="overlay">
            <div class="center" :class="button?'show-button': ''">
                <div class="content row">
                    <div class="content-text" v-for="(content, c) in contents" :key="c" v-html="content">
                    </div>
                    <h3>Socials</h3>
                    <ul class="socials">
                        <li v-for="(link, social) in urls" v-if="link != null" :key="social">
                            <a :href="link == null? '#' : link" :target="link == null? '_window' : '_blank'">{{ social }}</a>
                        </li>
                    </ul>
                </div>
                <div class="button-row row" v-if="button">
                    <router-link to="/contact" class="button big">
                        Let's get started!
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {absolute} from "@/App";

export default {
    name: "SocialsBannerSection",
    props: ['section'],
    data() {
        return {
            button: false
        }
    },
    computed: {
        image() {
            let image = this.section?.image ?? null;
            if (image == null) {
                return null;
            }
            return absolute(image.path);
        },
        contents() {
            let content = `${this.section?.content ?? "<p></p>"}`.replaceAll('/cockpit', absolute("cockpit"));
            if (content.indexOf('[button]') !== -1) {
                this.button = true;
                content = content.replaceAll('[button]', '')
            }
            if (content.indexOf('[split]') !== -1) {
                content = content.replaceAll("[split]", "</p>[split]<p>")
                return content.replaceAll("<p></p>", "").split('[split]')
            }
            return [content];
        },
        urls() {
            return this.$store.getters.socials ?? null
        }
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/sections/Banner";
</style>
